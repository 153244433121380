import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: "auto",
    marginRight: "auto",
    borderTop: "1px solid rgba(255,255,255,0.2)",
    paddingTop: 50,
    paddingBottom: 50,
  },
  spacer: {
    [theme.breakpoints.down("sm")]: {
      height: "5vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "20vh",
    },
  },
  copyright: {
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      justify="center"
      className={classes.root}
      md={12}
    >
      <Grid item md={10} justify="center">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.copyright}>
                Made with &hearts; in Los Angeles, CA - <br />
                &copy; 2022, FullSignal - All Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
