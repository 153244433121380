import React from "react";
import { makeStyles } from "@material-ui/styles";

import { ReactSVG } from "react-svg";

const useStyles = makeStyles({
  logoCenter: {
    textAlign: "center",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 200,
  },
  logoLeft: {
    display: "block",
    maxWidth: 300,
    marginLeft: 30,
    // marginTop: 40,
  },
});

import LogoVertical from "../assets/images/fullsignal-logo.svg";
import LogoHorizontal from "../assets/images/fullsignal-horizontal-logo.svg";
import { Link } from "@material-ui/core";

const Logo: React.FC<{ variant: any }> = ({ variant }) => {
  const classes = useStyles();
  const Logo = (variant: string) =>
    variant === "vertical" ? LogoVertical : LogoHorizontal;

  return (
    <Link to="/">
      <a href="/">
        {" "}
        <ReactSVG
          // @ts-ignore
          src={Logo()}
          afterInjection={(error, svg) => {
            if (error) {
              console.error(error);
              return;
            }
          }}
          beforeInjection={(svg) => {
            svg.classList.add(
              variant === "vertical" ? classes.logoCenter : classes.logoLeft
            );
            svg.setAttribute("style", "padding-top: 10px");
          }}
          evalScripts="always"
          fallback={() => <span>Error!</span>}
          renumerateIRIElements={false}
          wrapper="span"
          onClick={() => {
            // console.log('wrapper onClick');
          }}
        />
      </a>
    </Link>
  );
};

export const LogoVerticalComponent: React.FC<{ variant: any }> = ({
  variant,
}) => {
  const classes = useStyles();
  const Logo = (variant: string) =>
    variant === "vertical" ? LogoVertical : LogoVertical;

  return (
    <Link to="/">
      <a href="/">
        {" "}
        <ReactSVG
          // @ts-ignore
          src={Logo()}
          afterInjection={(error, svg) => {
            if (error) {
              console.error(error);
              return;
            }
          }}
          beforeInjection={(svg) => {
            svg.classList.add(
              variant === "vertical" ? classes.logoCenter : classes.logoLeft
            );
            svg.setAttribute("style", "padding-top: 10px");
          }}
          evalScripts="always"
          fallback={() => <span>Error!</span>}
          renumerateIRIElements={false}
          wrapper="span"
          onClick={() => {
            // console.log('wrapper onClick');
          }}
        />
      </a>
    </Link>
  );
};

export default Logo;
